.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-10 {
  padding-left: 10px;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.w-100 {
  width: 100%;
}

.d-v-center {
  display: flex;
  align-items: center;
}

.no-decoration {
  text-decoration: none;
}

.no-border {
  border: none !important;
  --border-width: 0 !important
}
